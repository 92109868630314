import React from "react";

import beforeafter from "../../assets/images/about/kanha-shanthi-vanam/before-after.png";

import daajidpatel from "../../assets/images/about/kanha-shanthi-vanam/Green-img.png";

const Ourjourney = () => {
  return (
    <div
      className="our-journey-wrapper subpages pad60"
      style={{ paddingBottom: "0px !important" }}
    >
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-md-4">
            <h3 className="title-heading"> OUR JOURNEY</h3>
            <p>
              Prior to 2010, this entire area was largely barren and dry. At
              outset, a massive plantation drive was undertaken with special
              emphasis being given to the endangered local species. Over the
              years, the green cover has steadily increased and the landscape
              has been transformed.
            </p>
          </div>
          <div className="col-md-8">
            <img className="img-fluid" src={beforeafter} width="100%" alt="Before" />
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={daajidpatel}
              width="100%"
              alt="Before"
            />
          </div>
          <div className="col-md-6">
            <h3 className="title-heading"> MEET THE LEADER KAMLESH D PATEL</h3>
            <p>
              Born in Gujarat, India, Kamlesh D Patel showed an early interest
              in meditation and spiritual growth. In 1976, as a pharmacy student
              in Ahmedabad, he met his Guide, Shri Ram Chandra of Shahjahanpur
              (Babuji), the founder and first President of Shri Ram Chandra
              Mission.
            </p>
            <p>
              After Graduating with honours in 1980, he moved to the U.S. and
              practiced pharmacy in New York City, while also continuing the
              Raja Yoga System of Sahaj Mar,g, with great devotion. He continues
              to excel in both his material career and spiritual life.
            </p>
            <p>
              Kamlesh continues to follow Shri Parthasarathi Rajagopalachari
              (Chariji), the second President of Shri Ram Chandra Mission, after
              Babuji’s passing, and has held a number of increasingly
              responsible roles as well as offering his support to the growth of
              the Mission worldwide.
            </p>
            <p>
              In October 2011, he was proclaimed as Chariji’s successor, due to
              Chariji’s failing physical health. Following Chariji’s passing on
              the 20th of December 2014, he became the spiritual Guide of Sahaj
              Marg System, and the third President of Shri Ram Chandra Mission.
              He is often endearingly referred to as Daaji. Dadaji and his wife
              have two sons.
            </p>
            <p className="pt-1">
              These days he is constantly travelling, conducting meditation with
              yogic transmission for seekers world wide, and speaking at the
              Mission's many centres and ashrams, as well as to the general
              public through newspaper articles, TV and radio. He is a vital
              role model for anyone wanting to evolve as a human being and
              expand consciousness. A Spiritual Catalyst, displaying the perfect
              blend of the Eastern heart and Western mind, he is able to connect
              with people from all walks of life and backgrounds, giving special
              attending to today’s youth.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Ourjourney;
