import React from "react";

import kanhaview from "../../assets/images/about/kanha-shanthi-vanam/kanha-view.png";

const Kanhashanthivanampages = () => {
  return (
    <div className="kanha-wrapper subpages pad60">
      <div className="container">
        <div className="row">
         <div className="col-md-5">
           <img src={kanhaview} className="img-fluid" alt="GKR" />
        </div>   
          <div className="col-md-7">
            <h3 className="title-heading"> KANHA SHANTI VANAM</h3>
            <p>
              {" "}
              Located on the outskirts of Hyderabad, Telangana, Kanha
              Shanti Vanam is the world headquarters of the Heartfulness
              Institute. The ashram is an ecological paradise, a place for
              spiritual retreat, where you can experience simple living in-tune
              with inner and outer nature.</p>
              <p> Kanha Shanti Vanam offers spiritual
              training along with interactive programs that promote overall
              wellness and opportunity to learn and contribute. </p>
              <p> In 2019, Kanha
              was awarded ‘The Pride of Telangana’, for inner wellness, by Round
              Table India & Times of India. </p> <p> The center also received the Indian
              Green Building Council (IGBC) Platinum award in 2019 for following
              world class environment friendly practices becoming the first
              meditation center in the world to achieve the feat.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kanhashanthivanampages;
