import React from "react";

const ForestPeace = () => {
  return (
    <div className="forest-perace-wrpper subpages pad60 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12 border-wrapper">
            <h3 className="title-heading"> THE FOREST OF PEACE</h3>
            <p>
              {" "}
              Kanha Shanti Vanam - Kanha is the epicenter of the Heartfulness
              Movement. Magnificent in its style and design, tranquil in its
              atmosphere, the beauty of the place lies in its subtlety; the
              natural and harmonious environment of the place resonates with the
              balance and love experienced by the hearts of those visiting and
              living in kanha.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForestPeace;
