import React from "react";

import Kanhashanthivanampages from "../components/about/kanhashanthivanam";

import Breadcrumbpages from "../components/about/breadcrumb";

import ForestPeace from "../components/about/forest-peace";

import Ourjourney from "../components/about/ourjourney";

import Aboutheartfulness from "../components/about/aboutheartfulness";

import Layout from "../layout/index";

import Seo from "../components/seo/index";

import Socialiconright from "../components/home/social-icon-right";

const Kanhashanthivanam = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Kanha Shanthi Vanam" />
      <Layout isSticky>
        <Breadcrumbpages/>
        <Socialiconright />
        <Kanhashanthivanampages />
        <ForestPeace />
        <Ourjourney />
        <Aboutheartfulness />
      </Layout>
    </div>
  );
};

export default Kanhashanthivanam;
