import React from "react";

import humanatity from "../../assets/images/about/kanha-shanthi-vanam/humanatity.png";

const Aboutheartfulness = () => {
  return (
    <div className="about-heartfulness-wrapper">
      <div className="blue_bg">
        <h3 className="title-heading"> ABOUT HEARTFULNESS</h3>
        <p>
          {" "}
          <b> Humanity's way forward is through the heart.</b>{" "}
        </p>
        <p>
          {" "}
          Heartfulness is the simple, and subtle practice of meditation that
          connects each one of us with the light and love in our hearts. Through
          our network of thousands of global HeartSpots and one magnificent
          smartphone app, we offer the world a daily practice that awakens our
          potential for a joyful existence. This precious gift of human
          transformation is provided free of charge.
        </p>
      </div>
      <div>
      <img
              className="img-fluid"
              src={humanatity}
              width="100%"
              alt="Before"
            />
      </div>
    </div>
  );
};
export default Aboutheartfulness;
